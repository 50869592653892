
.imageGalleryPreDiv {
    width: 100%;
    height: 250px;
}
.imageGalleryDiv {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 250px;
    border: 0;
}
.imageGalleryDiv > a > img {
    max-width: 100%;
    max-height: 100%;
}
.imageGalleryDiv > img {
    max-width: 100%;
    max-height: 100%;
}
.status-approved {
    margin-top: 8px;
    color: #64BB77;
}
.status-approved-comment {
    margin-top: 8px;
    margin-bottom: 8px;
}
.status-denied {
    margin-top: 8px;
    color: #AF0404;
}
.comment-indicator {
    position: absolute;
    top: 0;
    right: -8px;
    font-size: 14px;
}
